import React from 'react';
import '../style/Content.scss';
import imgAe from '../images/ae.png';
import imgCaption from '../images/caption.png';
import imgSrt from '../images/srt.png';
import imgSrt2 from '../images/srt2.png';
import imgSubs from '../images/subs.png';
import imgDJI from '../images/dji.jpg';
import imgTO from '../images/to.jpg';
import imgTE from '../images/te.jpg';
import imgGE from '../images/ge.jpg';

export default ({ compat, setOverlay }) => {
  const steps = [
    {
      image: imgCaption,
      title: 'Enable Video Subtitles',
      description: `Before your flights, make sure to enable the <strong>Video Captions</strong> or <strong>Video Subtitles</strong> option in the Camera Settings of the DJI App`,
      overlay: true
    },
    {
      image: imgDJI,
      title: 'Fly your drone',
      description: `Have fun, do work, <strong>just fly how you normally would</strong>. Each video you record will have its own telemetry.`
    },
    {
      image: imgSrt,
      title: 'Get creative with your data!',
      description: `<strong>Choose one of the tools below</strong> to visualize your data, extract it to formats, share it or enhance your videos with overlays.`
    }
  ];
  return (
    <div className="Content">
      <div className="steps">
        {steps.map((s, i) => (
          <div className="step" key={s.title}>
            <h3>
              {i + 1}. {s.title}
            </h3>
            <div className="stepContent">
              <a href={s.link} target="_blank" rel="noopener noreferrer">
                <div
                  className={`stepImage${
                    s.overlay || s.link ? ' linkable' : ''
                  }`}
                  style={{ backgroundImage: `url('${s.image}')` }}
                  onClick={() => {
                    if (s.overlay) setOverlay({ img: s.image, alt: s.title });
                  }}
                ></div>
              </a>
              <div
                className="stepDescription"
                dangerouslySetInnerHTML={{ __html: s.description }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="compatible">
        <h2>TOOLS</h2>
        <p>
          Compatible with{' '}
          {compat.map((p, i, arr) => (
            <span className="product" key={p}>
              {p}
              {i + 1 < arr.length ? ', ' : '.'}
            </span>
          ))}
        </p>
      </div>
      <div id="singleSteps">
        <div className="step">
          <h3>Telemetry Overlay</h3>
          <div className="stepContent">
            <a
              href="https://goprotelemetryextractor.com/drone-heads-up-display"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="stepImage linkable"
                style={{ backgroundImage: `url('${imgTO}')` }}
              ></div>
            </a>
            <div className="stepDescription">
              Easily create a <strong>heads-up display</strong> with the data on
              top of your videos with{' '}
              <a
                href="https://goprotelemetryextractor.com/drone-heads-up-display"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telemetry Overlay
              </a>
              . Customize every <strong>gauge, color, font, style</strong> and
              control the data processing.
            </div>
          </div>
        </div>
        <div className="step">
          <h3>Telemetry Extractor</h3>
          <div className="stepContent">
            <a
              href="https://goprotelemetryextractor.com/gopro-gps-telemetry-extract"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="stepImage linkable"
                style={{ backgroundImage: `url('${imgTE}')` }}
              ></div>
            </a>
            <div className="stepDescription">
              Load your data on the powerful{' '}
              <a
                href="https://goprotelemetryextractor.com/gopro-gps-telemetry-extract"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telemetry Extractor
              </a>{' '}
              software to visualize it alongside the video, compute new data
              streams, and convert them to file formats (<strong>GPX</strong>,{' '}
              <strong>CSV</strong>, <strong>KML</strong>,<strong>JSON</strong>
              ...) for use in professional environments like{' '}
              <strong>Google Earth</strong> / Maps / Street View,{' '}
              <strong>ArcGIS</strong>, <strong>Excel</strong> / Numbers /
              Sheets, <strong>Adobe After Effects</strong>... Compatible with
              many more camera brands and tracking devices.
            </div>
          </div>
        </div>
        <div className="step">
          <h3>Flight Log SRT Viewer</h3>
          <div className="stepContent">
            <a href="./srt-viewer" target="_blank" rel="noopener noreferrer">
              <div
                className="stepImage linkable"
                style={{ backgroundImage: `url('${imgSrt2}')` }}
              ></div>
            </a>
            <div className="stepDescription">
              Load your flight logs on the free{' '}
              <a href="./srt-viewer" target="_blank" rel="noopener noreferrer">
                DJI SRT Viewer
              </a>
              , <strong>visualize</strong> them on top of the{' '}
              <strong>map</strong> or <strong>satellite</strong> images, and
              convert them to other formats such as <strong>GPX</strong>,{' '}
              <strong>KML</strong>, <strong>mgJSON</strong> (After Effects),{' '}
              <strong>CSV</strong>, <strong>JSON</strong>, download a{' '}
              <strong>video</strong> or a <strong>photo</strong> of your path
              and metrics.
            </div>
          </div>
        </div>
        <div className="step">
          <h3>Subtitle Extractor</h3>
          <div className="stepContent">
            <a
              href="./subtitle-extractor"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="stepImage linkable"
                style={{ backgroundImage: `url('${imgSubs}')` }}
              ></div>
            </a>
            <div className="stepDescription">
              Some drones do not create a <strong>.SRT</strong> file, but embed
              the subtitles internally (<strong>Mavic Mini</strong>,{' '}
              <strong>Phantom 4 Pro</strong>, <strong>Inspire 2</strong>) Use
              the free{' '}
              <a
                href="./subtitle-extractor"
                target="_blank"
                rel="noopener noreferrer"
              >
                Subtitle Extractor
              </a>{' '}
              to extract the data to .SRT before using the{' '}
              <a href="./srt-viewer" target="_blank" rel="noopener noreferrer">
                DJI SRT Viewer
              </a>
              .
            </div>
          </div>
        </div>
        <div className="step">
          <h3>After Effects Telemetry Template</h3>
          <div className="stepContent">
            <a
              href="https://goprotelemetryextractor.com/mavic-phantom-inspire-telemetry"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="stepImage linkable"
                style={{ backgroundImage: `url('${imgAe}')` }}
              ></div>
            </a>
            <div className="stepDescription">
              {' '}
              After extracting the data with the{' '}
              <a href="./srt-viewer" target="_blank" rel="noopener noreferrer">
                SRT Viewer
              </a>
              , use this{' '}
              <a
                href="https://goprotelemetryextractor.com/mavic-phantom-inspire-telemetry"
                target="_blank"
                rel="noopener noreferrer"
              >
                AE template project
              </a>{' '}
              to add customizable telemetry metrics while staying within your
              professional <strong>Adobe After Effects</strong> worflow.
            </div>
          </div>
        </div>
        <div className="step">
          <h3>Google Earth 3D maps</h3>
          <div className="stepContent">
            <a
              href="https://youtu.be/dhgQ8aPUq_U"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="stepImage linkable"
                style={{ backgroundImage: `url('${imgGE}')` }}
              ></div>
            </a>
            <div className="stepDescription">
              Follow{' '}
              <a
                href="https://youtu.be/dhgQ8aPUq_U"
                target="_blank"
                rel="noopener noreferrer"
              >
                this tutorial
              </a>{' '}
              to create an impressive <strong>3D map view</strong> of your
              flight path in <strong>Google Earth</strong> and sync it with your
              video.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
