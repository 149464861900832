import React from 'react';
import '../style/TopSection.scss';

const TopSection = ({ prs, pageLoaded }) => {
  return (
    <div className="TopSection">
      <a href="#top" id="top" className="sr-only">
        top
      </a>
      <div className="landing">
        <div className="info">
          <div className="title">
            <h1>DJI Telemetry Overlay </h1>
            <h2>Heads-up display for your drone videos</h2>
          </div>
          <div className="nav">
            {prs.map((pr, i, { length }) => (
              <div key={pr} className="navItem">
                {pr}
              </div>
            ))}
          </div>
        </div>
        <div className="youtube">
          {pageLoaded && (
            <iframe
              title="youtube-main"
              src="https://www.youtube.com/embed/LYvMqHRagA4"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
          <img
            className="placeholder"
            src={`https://img.youtube.com/vi/LYvMqHRagA4/mqdefault.jpg`}
            alt="Loading video"
          />
        </div>
      </div>
      <div className="preProducts">
        <div className="products">
          <h3>
            <a href="#products"> How to </a>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
