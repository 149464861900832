import React, { useState, useEffect } from 'react';
import '../style/ImageOverlay.scss';

export default ({ img, alt = '', setOverlay }) => {
  const [transitioning, setTranstioning] = useState(true);

  useEffect(() => {
    setTranstioning(false);
  }, []);

  const handleSetOverlay = () => {
    setTranstioning(true);
    setTimeout(() => {
      setOverlay({ img: null, alt: null });
    }, 300);
  };

  return (
    <div className={`ImageOverlay${transitioning ? ' transition' : ''}`}>
      <div className="bg" onClick={handleSetOverlay}></div>
      <button className="close" onClick={handleSetOverlay}>
        X
      </button>
      <img src={img} alt={alt} />
    </div>
  );
};
