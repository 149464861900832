import React from 'react';
import '../style/Footer.scss';

const Footer = () => {
  return (
    <div className="Footer">
      This app was created by{' '}
      <a href="https://prototyping.barcelona">Telemetry Overlay S.L.</a> |
      Contact:{' '}
      <a href="mailto:app@prototyping.barcelona">app@prototyping.barcelona</a> |{' '}
      Subscribe to the{' '}
      <a
        href="http://eepurl.com/gVml41"
        target="_blank"
        rel="noopener noreferrer"
      >
        Telemetry newsletter
      </a>
    </div>
  );
};

export default Footer;
