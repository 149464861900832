import React, { useState, useEffect } from 'react';
import TopSection from './TopSection';
import Footer from './Footer';
import Content from './Content';
import ImageOverlay from './ImageOverlay';
import '../style/App.scss';

const App = () => {
  const [overlay, setOverlay] = useState({ img: null, alt: null });
  const compatible = [
    'Mavic 3 Pro',
    'Mini 4',
    'Mini 3 Pro',
    'Mini 3',
    'Mavic 3',
    'Air 2S',
    'Mavic Air 2',
    'Mavic 2 Pro',
    'Mavic 2 Zoom',
    'Mavic Mini',
    'Mavic Pro',
    'Mini 2',
    'Mini SE',
    'FPV',
    'Avata 2',
    'Avata',
    'Phantom 3',
    'Phantom 4',
    'Phantom 4 Pro',
    'Inspire',
    'Matrice Series'
  ];
  const compatibleSimple = [
    'Mavic, Air, Mini, Pro, Zoom',
    'FPV, Avata',
    'Phantom (3, 4, Pro)',
    'Inspire',
    'Matrice'
  ];
  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    const pageIsLoaded = () => setImmediate(() => setPageLoaded(true));
    window.addEventListener('load', pageIsLoaded);
    if (document.readyState === 'complete') pageIsLoaded();
    return () => window.removeEventListener('load', pageIsLoaded);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      {overlay.img && <ImageOverlay {...overlay} setOverlay={setOverlay} />}
      <TopSection prs={compatibleSimple} pageLoaded={pageLoaded} />
      <a href="#products" id="products" className="sr-only">
        how to
      </a>
      <Content compat={compatible} setOverlay={setOverlay} />
      <Footer />
    </div>
  );
};

export default App;
